import React from "react";
import SearchResultPage from "../../../templates/search-results-template";

const OffPlanProjectsResultsSales = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/off-plan-projects/for-sale/" 
                pcategorytype="new_development_projects" 
                ptype="offplan" 
                ptypetag="for-sale" 
                pstatustype=""
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
            />
        </React.Fragment>
    );
};

export default OffPlanProjectsResultsSales;